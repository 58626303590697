import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import { graphql, useStaticQuery } from 'gatsby';
import TextPage, { TextBlock } from '../components/text-page';
import commonMessageValues from '../templates/formatjs-message-values';

const AfPay = () => {
  const images = useStaticQuery(graphql`{
    file(relativePath: {eq: "afpay.jpg"}) {
      childImageSharp {
        gatsbyImageData(height: 800, quality: 50, layout: FIXED)
      }
    }
  }
  `);
  const intl = useIntl();
  const messages = intl.messages;
  const textBlocks: TextBlock[] = [{
    header: `${messages.domestic_card_scheme}`,
    message: <FormattedMessage id="message_domestic_card_scheme" values={commonMessageValues}/>
  }];

  return <>
    <TextPage locale={intl.locale} title={`${messages.domestic_card_scheme}`} textBlocks={textBlocks} otherElements={
      <>
        <div className="ui header horizantal divider"/>
        <div className="ui centered two column grid">
          <div className="column">
            <GatsbyImage
              image={images.file.childImageSharp.gatsbyImageData}
              alt="AfPay Images"
              className="ui centered big image"/>
          </div>
        </div>
      </>
    }/>
  </>;
};

export default AfPay;